import React from "react";
import pageNotFound from '../../static/assets/images/page-not-found.png'
import i18n from "i18n-js";
const PageNotFound = () => {
    return (
        <div style={{textAlign:'center',marginTop:'100px'}}>
            {/* <h2>{i18n.t('no-page')}</h2> */}
            <img src={pageNotFound} />
        </div>
    );
};

export default PageNotFound;
